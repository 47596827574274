.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.square {
  background: #fff;
  border: 1px solid #999;
  float: left;
  font-size: 24px;
  font-weight: bold;
  line-height: 34px;
  height: 34px;
  margin-right: -1px;
  margin-top: -1px;
  padding: 0;
  text-align: center;
  width: 34px;
}

.seed {
  background: #fff;
  font-size: 24px;
  font-weight: bold;
  line-height: 34px;
  height: 34px;
  margin-right: -1px;
  margin-top: -1px;
  padding: 0;
  text-align: center;
  width: 34px;
}

.seedChosen {
  background: #dcdbdb;
  font-size: 24px;
  font-weight: bold;
  line-height: 34px;
  height: 34px;
  margin-right: -1px;
  margin-top: -1px;
  padding: 0;
  text-align: center;
  width: 34px;
}

.store-row {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 6px;
}

.board-row {
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  grid-gap: 3px;
}

.board-row:after {
  clear: both;
  content: '';
  display: grid;
}

.status {
  margin-bottom: 10px;
}
.game {
  display: flex;
  flex-direction: row;
}

.game-info {
  margin-left: 20px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.plant-stats {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #f7f7f7;
  border-radius: 4px;
}

.plant-image {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 10px;
}

.plant-info {
  color: #333;
}

.plant-info h2 {
  font-size: 18px;
  margin-bottom: 5px;
}

.plant-info p {
  font-size: 14px;
  margin-bottom: 2px;
}

.plant-stats-container {
  position: relative;
  margin-left: 20px;
  visibility: hidden;
}

.garden-container {
  flex: 1;
}

.board-container {
  display: flex;
}


@media (max-width: 768px) {
  .board-container {
    flex-direction: column;
  }

  .plant-stats-container {
    margin-top: 20px;
    margin-left: 0;
    visibility: hidden;
  }
}